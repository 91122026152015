import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import "bootstrap/dist/css/bootstrap.css";
import Fetch from "./utils/fetch";
import "bootstrap/dist/js/bootstrap.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronUp,
  faAnglesDown,
  faCopyright,
  faComments,
  faHeadphones,
  faCheck,
  faCircleExclamation,
  faPhone,
  faLocationDot,
  faLock,
  faHashtag,
  faStar,
  faMoneyBills,
  faMagnifyingGlass,
  faUser,
  faCreditCard,
  faMap,
  faWallet,
  faCircle,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTelegram,
  faWhatsapp,
  faFacebookF,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faCopyright as copy2,
  faUser as user2,
  faStar as star2,
  faCircle as circle2,
} from "@fortawesome/free-regular-svg-icons";
// import { faCopyright as copy2 } from '@fortawesome/free-solid-svg-icons';

library.add(
  faUserSecret,
  faChevronUp,
  faAnglesDown,
  faCopyright,
  faComments,
  faHeadphones,
  faCheck,
  faChevronUp,
  faCircleExclamation,
  faPhone,
  faLocationDot,
  faLock,
  faHashtag,
  faStar,
  faMoneyBills,
  faMagnifyingGlass,
  faUser,
  faCreditCard,
  faMap,
  faWallet,
  faCircle,
  faUserPlus,
  faFacebook,
  faInstagram,
  faTelegram,
  faWhatsapp,
  faFacebookF,
  faTwitter,
  faEnvelope,
  copy2,
  user2,
  star2,
  circle2
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en_us", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    // zh: require("./assets/languages/zh_CN.json"), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    // 'zh_hk': require('./assets/languages/zh_HK.json'),
    // en: require("./assets/languages/en_US.json"),
    // 'th_th': require('./assets/languages/th_TH.json'),
    // 'ja_jp': require('./assets/languages/ja_JP.json'),
    // 'ko_kr': require('./assets/languages/ko_KR.json'),
    // 'ms_my': require('./assets/languages/ms_MY.json'),
    // 'vi_vn': require('./assets/languages/vi_VN.json')
  },
});


// is Login enabled
Vue.prototype.$isLoginEnabled = false;


Vue.config.productionTip = false;
Vue.prototype.$langLoaded = false;
Vue.prototype.$loadLangAsync = function (lang) {
  return Fetch("/tipsLanguages/tips_languages", { language: lang })
    .then((r) => {
      if (r.code == 1) {
        i18n.locale = lang;
        Vue.prototype.$langLoaded = true;
        i18n.setLocaleMessage(lang, r.data);
        localStorage.setItem("lang", lang);
      } else {
        localStorage.setItem("lang", "en_us");
      }
      return true;
    })
    .catch(function () {
      localStorage.setItem("lang", "en_us");
      return false;
    });
};

// Redirect login page to the home if login is not enabled
if (!Vue.prototype.$isLoginEnabled) {
  router.beforeEach((to, from, next) => {
    if(to.name == "login" || to.path == "/login") {
      next({ name: 'home' })
    } else {
      next()
    }
  })
}

router.beforeResolve((to, from, next) => {
  var systemLang = localStorage.getItem("lang");

  if (systemLang === "" || systemLang == null) {
    let systemLangList = navigator.languages;
    systemLang = systemLangList[0];
    systemLang =
      systemLang.length > 2 ? systemLang.substring(0, 2) : systemLang;

    switch (systemLang) {
      case "en":
        systemLang = "en_us";
        break;

      case "zh":
        systemLang = "zh_cn";
        break;

      default:
        systemLang = "en_us";
        break;
    }
  }

  if (Vue.prototype.$langLoaded) {
    next();
  } else {
    Vue.prototype.$loadLangAsync(systemLang).then(() => next());
  }
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
