<template>
  <div id="app">
    <router-view />
    <div class="scrollTop" @click="scrollTop">
      <font-awesome-icon icon="fa-solid fa-chevron-up" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.scrollTop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 20px;
  background-color: #0d2d62;
  border-radius: 35px;
  cursor: pointer;
  border: solid 1px #fff;
  z-index: 5008;
}

.fa-chevron-up {
  color: #fff;
  width: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
