import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      redirect: {
        name: "home",
      },
    },
    {
      path: "/home",
      name: "home",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Home/HomePage.vue"
        ),
    },
    {
      path: "/allMovies",
      name: "allMovies",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/AllMovies/AllMovies.vue"
        ),
    },
    {
      path: "/myInvestment",
      name: "myInvestment",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/MyInvestments/MyInvestments.vue"
        ),
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Login/LoginComp.vue"
        ),
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Register/RegisterComp.vue"
        ),
    },
    {
      path: "/forgetPwrd",
      name: "forgetPwrd",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/ForgetPwrd/ForgetPwrd.vue"
        ),
    },
    {
      path: "/profilePage",
      name: "profilePage",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Profile/ProfilePage.vue"
        ),
    },
    {
      path: "/profilePage/:id",
      name: "profilePage",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Profile/ProfilePage.vue"
        ),
    },
    {
      path: "/aboutmain/:id",
      name: "aboutmain",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/AboutMain/AboutMain.vue"
        ),
    },
    {
      path: "/gallery",
      name: "gallery",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Gallery/GalleryPage.vue"
        ),
    },
    {
      path: "/bonus",
      name: "bonus",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/Bonus/BonusPage.vue"
        ),
    },
    {
      path: "/moviedetail/:id",
      name: "moviedetail",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/MovieDetail/MovieDetail.vue"
        ),
    },
  ],
});
